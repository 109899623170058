import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faLink } from '@fortawesome/free-solid-svg-icons';
import img from '../../img/baixar.svg'
class BaixarApp extends Component {
    componentDidMount(){
 document.title = "Baixar App"
    }
  render() {
    return (
      <>
        <section id="about-us" className="work section">
            <div className='area-404'>
            <img src={img}/>
            <h4>Baixe Nosso App Encurtador de Links</h4>
            <span>Simplifique o compartilhamento de URLs com nosso aplicativo encurtador de links.</span>
            <button className='btn-1' onClick={()=>{
           
            }}><FontAwesomeIcon icon={faLink} />Baixar</button>
            </div>
   
        </section>
      </>
    );
  }
}

export default BaixarApp;