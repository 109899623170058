import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faLink } from '@fortawesome/free-solid-svg-icons';
import img from '../../img/navegadores.svg'
class Extensao extends Component {
    componentDidMount(){
 document.title = "Extensão"
    }
  render() {
    return (
      <>
        <section id="about-us" className="work section">
            <div className='area-404'>
            <img src={img}/>
            <h4> Baixe Nossa Extensão Encurtadora de Links</h4>
            <span>Torne o compartilhamento de links mais simples e rápido com nossa extensão encurtadora de URLs para navegadores. </span>
            <button className='btn-1' onClick={()=>{
           
            }}><FontAwesomeIcon icon={faLink} />Baixar</button>
            </div>
   
        </section>
      </>
    );
  }
}

export default Extensao;