import React, { useState, useEffect } from 'react';
import { Route, Routes, Router, useNavigate} from "react-router-dom"
import Inicio from "./paginas/Inicio";
import Header from "./componets/Header";
import Page404 from "./paginas/Page404";
import Funcoes from "./paginas/Funcoes";
import Entrar from "./paginas/Entrar";
import Cadastro from "./paginas/Cadastro";
import RecuperSenha from "./paginas/RecuperSenha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Extensao from './paginas/Extensao';
import BaixarApp from './paginas/BaixarApp';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigateTo = useNavigate();
  const [isLoad, setIsload] = useState(true);
  useEffect(() => {
    const authToken = document.cookie.includes('acess_token');
    setIsload(false)
    setIsAuthenticated(authToken);
  }, []);


if(isLoad){
  return(<div className='loader'>
  <div class="spinner"></div>
  </div>)
}


if(isAuthenticated){
return(<>
   
</>)
}else{
  return (
    <>
    <Header/>
    <ToastContainer />
    <Routes>
    
    <Route exact  path="/"  element={<Inicio  navigate={navigateTo}/>} />
    <Route exact  path="/extensao"  element={<Extensao  navigate={navigateTo}/>} />
    <Route exact  path="/baixar-app"  element={<BaixarApp  navigate={navigateTo}/>} />
    <Route exact  path="/funcoes"  element={<Funcoes/>} />
    <Route exact  path="/entrar"  element={<Entrar navigate={navigateTo}/>} />
    <Route exact  path="/recuperar-senha"  element={<RecuperSenha/>} />
    <Route exact  path="/cadastre-se"  element={<Cadastro/>} />
    <Route exact  path="*"  element={<Page404 navigate={navigateTo}/>} />

    </Routes>
  
    </>
  );
}
  
}

export default App;
